import React from 'react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout/layout';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Tags from '../components/blog/tags.js';
import { Seo } from '../components/layout/seo';

const BlogPostTemplate = ({ pageContext }) => {
  const post = pageContext.post;
  return (
    <Layout>
      {/* <Seo
        title={`${post.title} | ${siteTitle}`}
        pathname={this.props.location.pathname}
      ></Seo> */}
      {/* <BlogData
        headline={post.title}
        desc={post.description.childMarkdownRemark.html}
        slug={post.slug}
        img={post.heroImage.fluid.srcWebp}
        writer={post.author.name}
      /> */}

      <Post>
        <Title>{post.title}</Title>
        <Caption
          dangerouslySetInnerHTML={{
            __html: post.description?.childMarkdownRemark?.html,
          }}
        />
        <StaticImage
          css={css`
            margin: 25px 0px;
          `}
          src="../images/blogmtn.png"
          alt="Mountain Shaped Divider"
        />
        <Hero>
          <GatsbyImage
            alt={post.title}
            image={post.heroImage?.gatsbyImageData}
          />
        </Hero>
        <small>{post.heroImage?.description}</small>
        <BlogText
          dangerouslySetInnerHTML={{
            __html: post.body?.childMarkdownRemark?.html,
          }}
        />
        <Author>
          <Line />
          <FadedSpan>Author: {post.author.name}</FadedSpan>
          <FadedSpan>Date Published: {post.publishDate}</FadedSpan>
          <FadedSpan>
            Category: &nbsp;
            <Tags tags={post.tags} />
          </FadedSpan>
        </Author>
      </Post>
    </Layout>
  );
};

export const Head = ({ location, pageContext }) => {
  const description = pageContext.post.description.childMarkdownRemark.html
    .replace('<p>', '')
    .replace('</p>', '');
  return (
    <Seo
      title={pageContext.post.title}
      pathname={location.pathname}
      description={description}
    />
  );
};

export default BlogPostTemplate;

const Post = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2vw calc((100vw - 1200px) / 2);
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  margin-top: 40px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 34px;
  }
`;

const Hero = styled.div`
  width: 70%;
  padding: 10px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const FadedSpan = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #999999;
  display: flex;
`;
const Caption = styled(FadedSpan)`
  text-align: center;
`;

const Author = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  border-top: 20px;
  padding: 10px;
`;

const BlogText = styled.p`
  margin: 30px 10px;
  font-weight: 400;
  font-size: 19px;
  line-height: 35px;
`;

const Line = styled.hr`
  height: 1px;
  width: 60px;
  margin: 10px 0px;
  border: 2px solid #084e87;
  background-color: #084e87;
`;
