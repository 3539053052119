import React from 'react';
import styled from '@emotion/styled';

const TagDiv = styled.div`
  border-radius: 5px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 8px;
  background-color: 'rgba(0, 0, 0, 0.05)';
`;

const Tag = ({ selected, tag, children, onClick }) => {
  return (
    <TagDiv
      style={
        selected
          ? { backgroundColor: `#084E87`, color: 'white', cursor: 'pointer' }
          : {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
              color: 'black',
              cursor: 'pointer',
            }
      }
      key={tag}
      onClick={onClick}
    >
      {children}
    </TagDiv>
  );
};
export default Tag;
