import React from 'react';
import styled from '@emotion/styled';

import Tag from './tag';

const Small = styled.small`
  display: flex;
  flex-wrap: wrap;
`;

const Tags = ({ tags }) =>
  tags?.length > 0 && (
    <Small>
      {tags.map((tag) => (
        <Tag key={tag} children={tag} />
      ))}
    </Small>
  );

export default Tags;
